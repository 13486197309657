export default [
  {
    title: 'Marketing',
    description: 'O time de marketing cuida da comunicação interna e da aquisição de clientes, do branding e do social media.',
    button: {
      title: 'Confira as vagas',
      link: 'marketing',
    },
    image: {
      sm: require('../images/vagasMarketingSm.png'),
      md: require('../images/vagasMarketingMd.png'),
      lg: require('../images/vagasMarketingLg.png'),
      xl: require('../images/vagasMarketingXl.png'),
    },
  },
  {
    title: 'Tecnologia',
    description: 'Time responsável por cuidar do nosso Super App, de nossos produtos digitais e manter toda a estrutura tecnológica do Inter trabalhando em favor dos nossos clientes.',
    button: {
      title: 'Confira as vagas',
      link: 'tecnologia',
    },
    image: {
      sm: require('../images/vagasTecnologiaSm.png'),
      md: require('../images/vagasTecnologiaMd.png'),
      lg: require('../images/vagasTecnologiaLg.png'),
      xl: require('../images/vagasTecnologiaXl.png'),
    },
  },
  {
    title: 'Customer Journey',
    description: 'É o time que tem a missão de cuidar da primeira linha de atendimento do Inter. Engloba as áreas de Customer Care e Customer Success.',
    button: {
      title: 'Confira as vagas',
      link: 'customer journey',
    },
    image: {
      sm: require('../images/vagasCustomerJourneySm.png'),
      md: require('../images/vagasCustomerJourneyMd.png'),
      lg: require('../images/vagasCustomerJourneyLg.png'),
      xl: require('../images/vagasCustomerJourneyXl.png'),
    },
  },
  {
    title: 'Comercial',
    description: 'Esse time tem o desafio de cuidar de cada interação, cada contato, do passo a passo no relacionamento com nossos clientes.',
    button: {
      title: 'Confira as vagas',
      link: 'comercial',
    },
    image: {
      sm: require('../images/vagasComercialSm.png'),
      md: require('../images/vagasComercialMd.png'),
      lg: require('../images/vagasComercialLg.png'),
      xl: require('../images/vagasComercialXl.png'),
    },
  },
  {
    title: 'Design',
    description: 'Nosso time de Design é responsável por desenhar e cuidar de toda a experiência dos nossos clientes com nossos produtos digitais.',
    button: {
      title: 'Confira as vagas',
      link: 'design',
    },
    image: {
      sm: require('../images/vagsDesignSm.png'),
      md: require('../images/vagsDesignMd.png'),
      lg: require('../images/vagsDesignLg.png'),
      xl: require('../images/vagsDesignXl.png'),
    },
  },
  {
    title: 'People to Business',
    description: 'O time de Gente e Gestão atua na atração e retenção de talentos e em todo nosso processo de contratação.',
    button: {
      title: 'Confira as vagas',
      link: 'people to business',
    },
    image: {
      sm: require('../images/vagasPeopleToBusinessSm.png'),
      md: require('../images/vagasPeopleToBusinessMd.png'),
      lg: require('../images/vagasPeopleToBusinessLg.png'),
      xl: require('../images/vagasPeopleToBusinessXl.png'),
    },
  },
  {
    title: 'Segurança da Informação',
    description: 'Um time que zela pelo cuidado com as informações envolvidas no relacionamento com nossos clientes.',
    button: {
      title: 'Confira as vagas',
      link: 'segurança da informação',
    },
    image: {
      sm: require('../images/vagasSegurancaDaInformacaoSm.png'),
      md: require('../images/vagasSegurancaDaInformacaoMd.png'),
      lg: require('../images/vagasSegurancaDaInformacaoLg.png'),
      xl: require('../images/vagasSegurancaDaInformacaoXl.png'),
    },
  },
  {
    title: 'Investimentos',
    description: 'Time responsável pelos serviços e produtos da área de investimentos do Inter. Engloba as áreas de Mercados de Capitais, Inter Asset e o canal de atendimento de alta renda (Inter Win).',
    button: {
      title: 'Confira as vagas',
      link: 'investimentos',
    },
    image: {
      sm: require('../images/vagasInvestimentosSm.png'),
      md: require('../images/vagasInvestimentosMd.png'),
      lg: require('../images/vagasInvestimentosLg.png'),
      xl: require('../images/vagasInvestimentosXl.png'),
    },
  },
]
