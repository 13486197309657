import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Components
import OpenVideo from '../../../components/OpenVideo/index'

// Assets
import { VideoSection, ButtonVideo } from './style'

const Video = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <VideoSection
      className='d-flex align-items-center'
      fluid={[
        data.bgVideoMobile.fluid,
        {
          ...data.bgVideoMDLG.fluid,
          media: '(min-width: 767px)',
        },
        {
          ...data.bgVideoMDLG.fluid,
          media: '(min-width: 992px)',
        },
        {
          ...data.bgVideoXL.fluid,
          media: '(min-width: 1200px)',
        },
      ]}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 d-none d-md-block'>
            <h2 className='fs-32 fs-lg-40 lh-40 lh-lg-50 fw-600 text-white'>
              <span className='d-lg-block'>Trabalhe conosco. </span>
              <span className='d-lg-block'>Vem ser time </span>
              #SangueLaranja!
            </h2>
          </div>
          <div className='col-12 col-md-6 d-flex justify-content-center'>
            <OpenVideo link='https://www.youtube.com/embed/Aki0urdkSdE?&autoplay=1'>
              <ButtonVideo
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click video',
                    element_name: 'Assista ao vídeo',
                    section_name: 'Trabalhe conosco. Vem ser time #SangueLaranja!',
                    redirect_url: 'https://www.youtube.com/embed/Aki0urdkSdE?&autoplay=1',
                  })
                }}
              >
                <Img
                  fluid={data.play.fluid}
                  loading='eager'
                  alt='Assistir Vídeo'
                  title='Assista ao vídeo'
                />
              </ButtonVideo>
            </OpenVideo>
          </div>
        </div>
      </div>
    </VideoSection>
  )
}

export default Video
