import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import { orange } from '../../../styles/colors'
import { Link } from 'gatsby'

export const BenefistSection = styled.section`
  .react-multiple-carousel__arrow--right {
    right: -9px;
    
    @media (min-width: ${breakpoints.md}) {
      right: 183px;
    }

    @media (min-width: ${breakpoints.lg}) {
      right: 3px;
    }
  }
  .react-multiple-carousel__arrow--left {
    left: 0;
    
    @media (min-width: ${breakpoints.md}) {
      left: 183px;
    }

    @media (min-width: ${breakpoints.lg}) {
      left: -10px;
    }
  }
`

export const CarouselContent = styled.div`
  height: 216px;
  
  @media (min-width: ${breakpoints.lg}) {
    height: 250px;
  }
`

export const BtnOrange = styled(Link)`
  border: 1px solid ${orange.extra};
  width: 100%;

  &:hover {
    opacity: 0.8
  }

  @media (min-width: ${breakpoints.md}) {
    width: 312px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 270px;
  }
`
