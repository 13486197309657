import styled from 'styled-components'

import imageBg from '../../assets/images/vemSerSangueLaranjaBg.png'

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background-color: #ff7a00;
  background-image: url(${imageBg});
  margin-top: 32px;
`
