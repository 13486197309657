import React from 'react'

import { Section } from './style'

const SangueLaranja = () => {
  return (
    <Section>
      <div className='conatiner'>
        <h3 className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 text-white fw-600 mb-0'>
          Vem ser #SangueLaranja!
        </h3>
      </div>
    </Section>
  )
}

export default SangueLaranja
