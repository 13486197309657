import React from 'react'
import Img from 'gatsby-image'
import { orange } from '../../../styles/colors'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Components
import DefaultCarousel from '../../../components/Carousels/DefaultCarousel/index'
import OrangeIcon from '../../../components/MarkdownIcon/OrangeDsIcon'

// Assets
import BenefitsJSON from '../../assets/data/Benefits.json'
import { BenefistSection, CarouselContent, BtnOrange } from './style'

// Page Query
import usePageQuery from '../../pageQuery'

type beneficiosProps = {
  icon: string;
  title: string;
  description: string;
}

const Beneficios = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const HandleDataLayer = () => {
    sendDatalayerEvent({
      section: 'dobra_3',
      element_action: 'click button',
      element_name: 'Veja as vagas disponíveis',
      section_name: 'É MUUUUITO BENEFÍCIO - Benefícios Oferecidos*',
      redirect_url: `${window.location.origin}/carreiras`,
    })
  }

  const carouselBenefits = (
    <DefaultCarousel
      sm={{ items: 1, partialVisibilityGutter: 50 }}
      md={{ items: 2, partialVisibilityGutter: 70 }}
      lg={{ items: 2 }}
      xl={{ items: 2, partialVisibilityGutter: 70 }}
    >
      {
        BenefitsJSON.map((item: beneficiosProps, index: number) => (
          <CarouselContent className='border rounded-12 mr-3 px-3 py-3' key={index}>
            <div><OrangeIcon size='MD' color={orange.extra} icon={item.icon} /></div>
            <div className='mt-3'>
              <p className='fs-18 mt-3 fw-700 text-grayscale--500 mb-1'>{item.title}</p>
              <p className='fs-14 text-grayscale--400'>{item.description}</p>
            </div>
          </CarouselContent>
        ))
      }
    </DefaultCarousel>
  )

  return (
    <BenefistSection id='beneficios' className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <Img fluid={data.beneficios.fluid} alt='Moça sorrindo e tomando uma bebida' />
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 offset-xl-1 mt-3 mt-md-0'>
            <span className='fs-14 text-grayscale--300 fw-600'>É MUUUUITO BENEFÍCIO</span>
            <h2 className='text-grayscale--500 fs-32 fs-xl-40 lh-40 lh-lg-50 lh-xl-60 mt-2'>
              Benefícios Oferecidos*
            </h2>
            <div className='d-md-none d-lg-block'>
              {carouselBenefits}
              <div className='d-flex justify-content-center justify-content-lg-start mt-2'>
                <BtnOrange
                  onClick={HandleDataLayer}
                  to='carreiras'
                  title='Veja as vagas disponíveis'
                  className='fs-14 text-center text-white bg-orange--extra rounded-8 px-3 px-md-5 px-lg-3 py-2 mt-4'
                >
                  Veja as vagas disponíveis
                </BtnOrange>
              </div>
            </div>
          </div>
        </div>
        <div className='row d-none d-md-block d-lg-none'>
          <div className='col-12'>
            {carouselBenefits}
            <div className='d-flex justify-content-center mt-md-3'>
              <BtnOrange
                onClick={HandleDataLayer}
                to='carreiras'
                title='Veja as vagas disponíveis'
                className='fs-14 text-center text-white bg-orange-extra rounded-8 px-5 py-2'
              >
                Veja as vagas disponíveis
              </BtnOrange>
            </div>
          </div>
        </div>
        <div className='row mt-4 mt-md-5'>
          <div className='col-12'>
            <span className='fs-10 lh-12 fw-400 text-grayscale--400'>*Existem diferenças de benefícios entre as empresas do grupo.</span>
          </div>
        </div>
      </div>
    </BenefistSection>
  )
}

export default Beneficios
