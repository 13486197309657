import React from 'react'
import Img from 'gatsby-image'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Page Query
import usePageQuery from '../../pageQuery'

// Assets
import { HeroSection, BtnOrange } from './style'

const Hero = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <HeroSection className='pb-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-md-none'>
            <Img fluid={data.hero.fluid} alt='Moça sorrindo e usando computador' />
          </div>
          <div className='col-12 col-md-6 offset-md-6 col-lg-5 offset-lg-7 mt-3 mt-md-0'>
            <h1 className='text-grayscale--500 fs-lg-40 fs-xl-48 lh-40 lh-lg-50 lh-xl-60 fw-700'>Simplificar é seguir lado a lado</h1>
            <p
              className='text-grayscale--500 fs-14 lh-20 mt-3 mt-md-0'
            >
              O que nos move é a inovação, e ela vem de pessoas.
              E cada pessoa que participa desse processo tem papel fundamental no nosso trabalho e nessa jornada de crescimento.
            </p>
            <BtnOrange
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_1',
                  element_action: 'click button',
                  element_name: 'Veja nossas vagas disponíveis',
                  section_name: 'Simplificar é seguir lado a lado',
                  redirect_url: `${window.location.origin}/carreiras`,
                })
              }}
              to='carreiras'
              title='Veja nossas vagas disponíveis'
              className='fs-14 text-center text-white bg-orange--extra rounded-8 px-5 py-2 mt-md-2'
            >
              Veja nossas vagas disponíveis
            </BtnOrange>
          </div>
        </div>
      </div>
    </HeroSection>
  )
}

export default Hero
