import styled from 'styled-components'

export const ModalContent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1050;
    animation: fadeIn 500ms;
    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.7;
    }
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      display: block;
      text-align: center;
      height: 60vh;
      max-width: 1100px;
      @media (min-width: map-get($inter-grid-breakpoints, sm)) {
        height: 533px;
        max-width: 1100px;
      }
      .close-button {
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid $white;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        position: absolute;
        top: -50px;
        cursor: pointer;
        @media (min-width: map-get($inter-grid-breakpoints, lg)) {
          right: -10px;
        }
        @media (min-width: map-get($inter-grid-breakpoints, xl)) {
          right: -50px;
        }
      }
    }

`
