import React from 'react'
import { createPortal } from 'react-dom'


// styles
import { ModalContainer } from './style'

// types
type ModalProps = {
  children: React.ReactNode
  isModalOpen: boolean
  setIsModalOpen: Function
  locationToRender: Element
}

function Modal ({ children, isModalOpen, setIsModalOpen, locationToRender }: ModalProps) {
  function closeModal () {
    setIsModalOpen(false)
  }

  return (
    isModalOpen ? (
      createPortal(
        <ModalContainer>
          <div className='backdrop' onClick={closeModal} />
          {children}
        </ModalContainer>,
        locationToRender,
      )
    ) : (
      <></>
    )
  )
}

export default Modal
