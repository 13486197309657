
import React from 'react'

// components
import Layout from '../components/Layout'
import Hero from './sections/hero/_index'
import Video from './sections/video/_index'
import Beneficios from './sections/beneficios/_index'
import Depoimentos from './sections/depoimentos/_index'
import Areas from './sections/areas/_index'

import SangueLaranja from './sections/sangueLaranja/_index'

// assets
import pageContext from './pageContext.json'
import { Wrapper } from './style'

const IndexPage = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext} isHome isGoTech={false}>
        <Hero />
        <Video />
        <Beneficios />
        <Depoimentos />
        <Areas />
        <SangueLaranja />
      </Layout>
    </Wrapper>
  )
}

export default IndexPage
