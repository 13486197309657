import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import { orange } from '../../../styles/colors'
import { Link } from 'gatsby'

import bgHeroMD from '../../assets/images/hero-md.png'
import bgHeroLG from '../../assets/images/hero-lg.png'
import bgHeroXL from '../../assets/images/hero-xl.png'

export const HeroSection = styled.section`
  min-height: 600px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 580px;
    background-image: url(${bgHeroMD});
    background-repeat: no-repeat;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 650px;
    background-image: url(${bgHeroLG});
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${bgHeroXL});
  }
`

export const BtnOrange = styled(Link)`
  border: 1px solid ${orange.extra};
  width: 100%;
  float: left;
  
  &:hover {
    opacity: 0.8;
  }
`
