import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'

type imageProps = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

type sectionProps = {
  image: imageProps;
}

export const Section = styled.section`
  background-color: #ff7a00;
  min-height: 712px;
  display: flex;
  align-items: flex-end;
  background-image: url(${(props: sectionProps) => props.image.sm});
  background-repeat: no-repeat;
  background-size: contain;

  .container {
    position: absolute;
    bottom: 30px;
    
    @media (min-width: ${breakpoints.md}) {
      position: initial;
    }
  }

  .react-multi-carousel-track {
    padding: 10px 0 50px;
  }

  @keyframes bgImage {
    from {
      background-position-x: 200%;
    }

    to {
      background-position-x: 100%;
    }
  }
  
  @media (min-width: ${breakpoints.md}) {
    min-height: 540px;
    align-items: center;
    background-image: url(${(props: sectionProps) => props.image.md});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 700px;
    background-image: url(${(props: sectionProps) => props.image.lg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 660px;
    background-image: url(${(props: sectionProps) => props.image.xl});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    animation-duration: 1s;
  }

  @media (min-width: ${breakpoints.xxl}) {
    min-height: calc(100vh - 112px);
  }

  .title {
    @media (min-width: ${breakpoints.lg}) {
      min-height: 120px;
    }
  }
  
  .description {
    min-height: 60px;
  }

  .react-multi-carousel-dot-list {
    button {
      background: #ffffff !important;
    }
  }

  .react-multiple-carousel__arrow {
    svg {
      fill: #ffffff !important;
    }
  }
`

export const Button = styled.a`
  width: 100%;
  height: 48px;
  background: #fff;
  color: #ff7a00;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-radius: 8px;
  margin-top: 32px;

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #ff7a00;
    opacity: 0.9;
  }

  @media(min-width: ${breakpoints.xl}) {
    margin-top: 40px;
  }
`
