import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import BackgroundImage from 'gatsby-background-image'

export const VideoSection = styled(BackgroundImage)`
  height: 300px;

  @media (min-width: ${breakpoints.md}) {
    height: 500px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 700px;
  }
`

export const ButtonVideo = styled.button`
  width: 104px;
  background: transparent;
  border: none;
  top: 50%;
`
