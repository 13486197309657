import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'

import imageBgXl from '../../assets/images/depoimemtosBgXl.png'
import imageBg from '../../assets/images/depoimemtosBgCarrousel.png'

export const Section = styled.section`
  background-color: #F5F6FA;

  @media(min-width: ${breakpoints.xl}) {
    background-image: url(${imageBgXl});
    min-height: 712px;
    background-size: 116px;
    background-position-y: center;
    background-repeat: no-repeat;
  }

  .react-multi-carousel-track {
    padding: 0 0 50px;
  }

  .react-multiple-carousel__arrow--right {
    right: -9px;
    
    @media (min-width: ${breakpoints.md}) {
      right: 183px;
    }
  }
  .react-multiple-carousel__arrow--left {
    left: 0;
    
    @media (min-width: ${breakpoints.md}) {
      left: 183px;
    }

  }
`
export const Content = styled.div`
  @media(min-width: ${breakpoints.lg}) {
    background-image: url(${imageBg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 72%;
  }
`
