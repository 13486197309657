import React from 'react'
import Img from 'gatsby-image'

// pageQuery
import pageQuery from '../../pageQuery'

// json
import depositionsJSON from '../../assets/data/despositions.json'

// components
import DefaultCarousel from '../../../components/Carousels/DefaultCarousel'

// styles
import { Section, Content } from './style'

type despositionsProps = {
  image: string;
  employee: string;
  description: string;
}

const Depoimemtos = () => {
  const data = pageQuery()

  return (
    <Section id='depoimentos' className='py-5 d-flex align-items-center'>
      <div className='container'>
        <DefaultCarousel
          sm={{ items: 1 }}
          md={{ items: 1 }}
          lg={{ items: 1 }}
          xl={{ items: 1 }}
        >
          {
            depositionsJSON.map((item: despositionsProps) => (
              <Content className='row d-flex align-items-center justify-content-between' key={item.image}>
                <div className='col-12 col-md-6 col-lg-5'>
                  <Img fluid={data[item.image].fluid} />
                </div>

                <div className='col-12 col-md-6 col-lg-6 mt-3 mt-md-0'>
                  <h2 className='text-grayscale--500 fs-28 lh-34 fs-lg-40 fs-xl-48 lh-40 lh-lg-50 lh-xl-60'>
                    Como é ser #SangueLaranja
                  </h2>
                  <p className='fs-16 lh-19 fs-xl-18 text-grayscale--500 fw-400'>{item.description}</p>
                  <h3 className='fs-16 lh-19 text-grayscale--500 fw-600'>{item.employee}</h3>
                </div>
              </Content>
            ))
          }
        </DefaultCarousel>
      </div>
    </Section>
  )
}

export default Depoimemtos
