import { white } from '../../styles/colors'
import { breakpoints } from '../../styles/breakpoints'

import styled, { keyframes } from 'styled-components'

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const ModalContainer = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  overflow-y: auto;

  .backdrop {
    background-color: rgba(0, 0, 0, .5);
    height: 100%;
    opacity: 0.8;
    position: fixed;
    width: 100%;
    z-index: -1;
  }
`

export const ContainerModal = styled.div`
 background-color: ${white};

  min-height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;  
  right: 0;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;

  @media (min-width: ${breakpoints.md}) {
    max-width: 552px;
    padding: 40px 20px;
  }

`
export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`
