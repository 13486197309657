import React, { useState } from 'react'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'
import dataJson from '../../assets/data/vagasData'

import OrangeDsIcon from '../../../components/OrangeDsIcon'

import DefaultCarousel from '../../../components/Carousels/DefaultCarousel'

// Styles
import { Section, Button } from './style'

type buttonProps = {
  title: string;
  link: string;
}

type imageProps = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

type dataJsonProps = {
  title: string;
  description: string;
  button: buttonProps;
  image: imageProps;
}

const Areas = () => {
  const [ index, setIndex ] = useState<number>(0)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='area-de-atuacao' className='py-5 position-relative' image={dataJson[index].image} style={{ animationName: `${index} && 'bgImage'` }}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(event: number) => setIndex(event)}
            >
              {
                dataJson.map((item: dataJsonProps) => (
                  <div key={item.title} className='px-2'>
                    <h2 className='text-white fs-20 fs-lg-40 fs-xl-48 lh-40 lh-lg-50 lh-xl-60 mb-3 title'>
                      {item.title}
                    </h2>
                    <div className='d-flex'>
                      <div className='mr-2'>
                        <OrangeDsIcon size='MD' color='#ffffff' icon='computer-call' />
                      </div>
                      <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-white description'>
                        {item.description}
                      </p>
                    </div>
                    <Button
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_5',
                          element_action: 'click button',
                          element_name: `Confira as vagas - ${item.title}`,
                          section_name: item.title,
                        })
                      }}
                      href={`/carreiras/?&q=${encodeURIComponent(item.button.link)}`}
                      title={item.button.title}
                    >
                      {item.button.title}
                    </Button>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Areas
