import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: {originalName: { regex: "/hero-mobile/" }}) {
        fluid(maxWidth: 469, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      beneficios: imageSharp(fluid: {originalName: { regex: "/beneficios/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgVideoMobile: imageSharp(fluid: {originalName: { regex: "/bg-video-mobile/" }}) {
        fluid(maxWidth: 361, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgVideoMDLG: imageSharp(fluid: {originalName: { regex: "/bg-video-mdlg/" }}) {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgVideoXL: imageSharp(fluid: {originalName: { regex: "/bg-video-xl/" }}) {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      play: imageSharp(fluid: {originalName: { regex: "/play/" }}) {
        fluid(maxWidth: 104, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      depoimemtosImageChristianoRohlfs: imageSharp(fluid: {originalName: { regex: "/depoimemtosImageChristianoRohlfs/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      depoimemtosImageSofiaGloor: imageSharp(fluid: {originalName: { regex: "/depoimemtosImageSofiaGloor/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      depoimemtosImageGuilhermePinto: imageSharp(fluid: {originalName: { regex: "/depoimemtosImageGuilhermePinto/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      depoimemtosImageVictoriaCamposRibeiro: imageSharp(fluid: {originalName: { regex: "/depoimemtosImageVictoriaCamposRibeiro/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      carreirasHero: imageSharp(fluid: {originalName: { regex: "/carreirasHeroMobile/" }}) {
        fluid(maxWidth: 468, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
